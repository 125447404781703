<template>
  <div>

    <div class="form">
      <el-form label-width="120px"
               :hide-required-asterisk="true"
               ref="form"
               :model="form"
               :rules="rules" >
        <div class="content">
          <el-form-item label="产品照片" prop="productBanner">
<!--            :file-list="( this.$route.query.id && BannerFalg ? (this.form.productBanner ? [{url : this.form.productBanner}] : fileList ) : fileList )"-->
            <el-upload
                ref="uploadProductBanner"
                :class="uploadDisabledProductBanner"
                list-type="picture-card"
                accept=".jpg,.png"
                :action="action"
                :file-list="fileListBanner"
                :headers="headers"
                :limit="1"
                :on-change="handleChangePicProductBanner"
                :on-success="handleAvatarSuccessPicProductBanner"
                :before-upload="beforeAvatarUploadPicProductBanner">
              <i slot="default" class="el-icon-plus"></i>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
              <div class="upload_image" slot="file" slot-scope="{file}">
<!--                <img-->
<!--                    class="el-upload-list__item-thumbnail"-->
<!--                    :src="file.url" alt=""-->
<!--                >-->
                <el-image
                    v-if="file.url"
                    :src="file.url"
                    :preview-src-list="[file.url]">
                </el-image>
                <el-image-viewer
                    v-if="showViewer"
                    :on-close="closeViewer"
                    :url-list="[file.url]" />

                <span class="el-upload-list__item-actions">
                <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file,showViewer= true)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                    class="el-upload-list__item-delete"
                    @click="handleRemoveProductBanner(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="产品名称" prop="productName">
            <el-input v-model="form.productName" placeholder="请输入产品名称"></el-input>
          </el-form-item>
          <el-form-item label="副标题" prop="subhead">
            <el-input v-model="form.subhead" placeholder="请输入副标题"></el-input>
          </el-form-item>
          <el-form-item label="产品价格" prop="price">
            <el-input v-model="form.price" clearable placeholder="请输入产品价格"></el-input>
          </el-form-item>
          <el-form-item label="产品类别" prop="productCategory">
            <el-select v-model="form.productCategory" clearable placeholder="请选择产品类别"
                       @change="changeProductItemList"
                       :disabled="$route.query.id != null " >
              <el-option v-for="item in $store.state.category"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否热销" prop="isHot">
            <el-radio-group v-model="form.isHot">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="适用性别" prop="fitGender">
            <el-radio-group v-model="form.fitGender" @change="changeProductItemList">
              <el-radio v-for="item in $store.state.fitGender"
                        :key="item.dictKey"
                        :label="item.dictKey">{{ item.dictValue }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="适用年龄" prop="fitAge">
            <el-select v-model="form.fitAge" clearable placeholder="请选择适用年龄">
              <el-option v-for="item in $store.state.fitAge"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品特性" prop="productFeature" >
            <el-select v-model="form.productFeature" clearable placeholder="请选择产品特性">
              <el-option v-for="item in $store.state.productFeature"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="产品类型" prop="productType">-->
<!--&lt;!&ndash;            :disabled="$route.query.id != null || form.productCategory == 2 || form.productCategory == 3"&ndash;&gt;-->
<!--&lt;!&ndash;            @change="$forceUpdate()"&ndash;&gt;-->
<!--            <el-radio-group v-model="form.productType">-->
<!--              <el-radio :label="'0'">单品</el-radio>-->
<!--              <el-radio :label="'1'">套餐</el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->

<!--          <el-form-item label="产品类型" prop="productType">-->
<!--            <el-radio-group v-model="form.productType">-->
<!--              <el-radio :label="'0'">正常产品</el-radio>-->
<!--              <el-radio :label="'1'">定制产品</el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->

          <el-form-item label="预约服务" prop="appointService" >
            <el-select v-model="form.appointService" multiple collapse-tags clearable placeholder="请选择预约服务">
              <el-option v-for="item in $store.state.service"
                         :key="item.dictKey"
                         :label="item.dictValue" :value="item.dictKey"></el-option>
            </el-select>
          </el-form-item>
<!--          v-if="form.productType == 0"-->
          <el-form-item label="包含项目" prop="productItemList" >
            <el-select v-model="form.productItemList" multiple clearable placeholder="请选择体检项目" @change="changeProductItemList" >
              <el-option-group
                  v-for="(group,index) in item"
                  :key="index"
                  :label="group.groupName">
                <el-option
                    v-for="item in group.itemList"
                    :key="item.itemId"
                    :label="item.itemName"
                    :value="item.itemId">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="附加项目" prop="plusItemList" >
            <el-select v-model="form.plusItemList" multiple clearable placeholder="请选择附加项" @change="changePlusItemList" >
              <el-option-group
                  v-for="(group,index) in restList"
                  :key="index"
                  :label="group.groupName">
                <el-option
                    v-for="item in group.itemList"
                    :key="item.itemId"
                    :label="item.itemName"
                    :value="item.itemId">
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="整合式产品" prop="subProductList" v-if="form.productType == 1" >-->
<!--            <el-select v-model="form.subProductList" multiple collapse-tags clearable placeholder="请选择整合式产品">-->
<!--              <el-option v-for="item in single"-->
<!--                         :key="item.id"-->
<!--                         :label="item.productName" :value="item.id"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="套餐介绍" prop="productDesc">
            <el-input type="textarea" v-model="form.productDesc" clearable placeholder="请输入套餐介绍"></el-input>
          </el-form-item>
          <el-form-item label="产品介绍(图片)" prop="productIntro">
<!--            :file-list="( this.$route.query.id && IntroFalg ? ( this.form.productIntro ? [{url : this.form.productIntro}] : fileList ) : fileList )"-->
            <el-upload
                ref="uploadProductIntro"
                :class="uploadProductIntro"
                list-type="picture-card"
                accept=".jpg,.png"
                :action="action"
                :file-list="fileListIntro"
                :headers="headers"
                :limit="1"
                :on-change="handleChangePicProductIntro"
                :on-success="handleAvatarSuccessProductIntro"
                :before-upload="beforeAvatarUploadProductIntro">
              <i slot="default" class="el-icon-plus"></i>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
              <div class="upload_image" slot="file" slot-scope="{file}">
                <!--                <img-->
                <!--                    class="el-upload-list__item-thumbnail"-->
                <!--                    :src="file.url" alt=""-->
                <!--                >-->
                <el-image
                    v-if="file.url"
                    :src="file.url"
                    :preview-src-list="[file.url]">
                </el-image>
                <el-image-viewer
                    v-if="showViewerIntro"
                    :on-close="closeViewer"
                    :url-list="[file.url]" />

                <span class="el-upload-list__item-actions">
                <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file,showViewerIntro= true)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                    class="el-upload-list__item-delete"
                    @click="handleRemoveProductIntro(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="缩略图" prop="thumbnail">
<!--            :file-list="( this.$route.query.id && Thumbnail ? (this.form.thumbnail ? [{url : this.form.thumbnail}] : fileList ) : fileList )"-->
            <el-upload
                ref="uploadThumbnail"
                :class="uploadDisabledThumbnail"
                list-type="picture-card"
                accept=".jpg,.png"
                :action="action"
                :file-list="fileListThumbnail"
                :headers="headers"
                :limit="1"
                :on-change="handleChangePicThumbnail"
                :on-success="handleAvatarSuccessThumbnail"
                :before-upload="beforeAvatarUploadThumbnail">
              <i slot="default" class="el-icon-plus"></i>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件</div>
              <div class="upload_image" slot="file" slot-scope="{file}">
                <!--                <img-->
                <!--                    class="el-upload-list__item-thumbnail"-->
                <!--                    :src="file.url" alt=""-->
                <!--                >-->
                <el-image
                    v-if="file.url"
                    :src="file.url"
                    :preview-src-list="[file.url]">
                </el-image>
                <el-image-viewer
                    v-if="showViewerThumbnail"
                    :on-close="closeViewer"
                    :url-list="[file.url]" />

                <span class="el-upload-list__item-actions">
                <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file,showViewerThumbnail= true)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                    class="el-upload-list__item-delete"
                    @click="handleRemoveThumbnail(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
              </div>
            </el-upload>
          </el-form-item>
        </div>
        <el-form-item class="fixed">
          <div class="button">
            <el-button size="small" @click="Close" >取消</el-button>
            <el-button size="small" type="primary" v-if="($route.query.id && form.productStatus == 0) || ($route.query.id && form.productStatus == 2)" @click="grounding(form,'ON_SALE')" >上架</el-button>
            <el-button size="small" type="primary" v-if="$route.query.id && form.productStatus == 1" @click="grounding(form,'OFF_SALE')" >下架</el-button>
            <el-button size="small" type="primary" v-if="$route.query.id && form.productStatus != 1" @click="onSubmit(form)" >保存</el-button>
            <el-button size="small" type="primary" v-if="!$route.query.id" @click="onSubmit(form)" >保存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>

export default {
  name: "ProductDeta",
  data() {
    var validatorPrice = (rule, value, callback) => {
      if(value == 0) {
        callback(new Error("产品价格不能小于等于0"));
      }else {
        callback();
      }
    };
    return{
      action: process.env.VUE_APP_APIURL + "product-manager/upload",
      headers: {
        authorization: this.$store.state.token
      },
      form: {
        productBanner: null, // 产品照片
        productName: null, // 产品名称
        subhead: null, // 副标题
        price: null, // 产品价格
        productCategory: null, // 产品类别
        fitGender: null, // 使用性别
        fitAge: null, // 使用年龄
        productFeature: null, // 产品特性
        productType: null, // 产品类型
        appointService: [], // 预约服务
        productItemList: [], // 体检项目
        subProductList: [], // 整合式产品
        productDesc: null, // 套餐介绍
        productIntro: null, // 产品介绍(图片)
        thumbnail: null, // 缩略图
        productId: null, // 编辑ID
        plusItemList: [], // 附加项
      },
      rules: {
        productBanner: [
            { required: true, message: '请上传产品图片', trigger: 'change' }
        ],
        productName: [
            { required: true, message: '请输入产品名称', trigger: 'change' }
        ],
        subhead: [
            { required: true, message: '请输入副标题', trigger: 'change' }
        ],
        price: [
            { required: true, message: '请输入产品价格', trigger: 'change' },
            { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '产品价格不正确' },
            { validator: validatorPrice, trigger: 'change' }
        ],
        productCategory: [
            { required: true, message: '请选择产品类别', trigger: 'change' }
        ],
        isHot: [
          { required: true, message: '请选择是否热销', trigger: 'change' }
        ],
        fitGender: [
            { required: true, message: '请选择适用性别', trigger: 'change' }
        ],
        fitAge: [
            { required: true, message: '请选择适用年龄', trigger: 'change' }
        ],
        productFeature: [
            { required: true, message: '请选择产品特性', trigger: 'change' }
        ],
        productType: [
            { required: true, message: '请选择产品类型', trigger: 'change' }
        ],
        appointService: [
          { required: true, message: '请选择预约服务', trigger: 'change' }
        ],
        subProductList: [
            { required: true, message: '请输入整合式产品', trigger: 'change' }
        ],
        productDesc: [
            { required: true, message: '请输入套餐介绍', trigger: 'change' }
        ],
        productIntro: [
            { required: true, message: '请上传产品介绍(图片)', trigger: 'change' }
        ],
        thumbnail: [
            { required: true, message: '请上传缩略图', trigger: 'change' }
        ],
      },
      fileListBanner:[],
      fileListIntro:[],
      fileListThumbnail:[],
      restList: [], // 附加项select
      plusLength: 0,
      // 体检项目
      single: [],
      // 整合式
      item: [],
      uploadDisabledProductBanner: null,
      BannerFalg: true,
      uploadProductIntro: null,
      IntroFalg: true,
      uploadDisabledThumbnail: null,
      Thumbnail: true,
      showViewer: false,
      showViewerIntro: false,
      showViewerThumbnail: false,
    }
  },
  watch: {
    // 'form': {
    //   handler(newValue) {
    //     if(newValue.productCategory == 2 || newValue.productCategory == 3) {
    //       newValue.productType = '1'
    //     }
    //   },
    //   deep: true
    // }
  },
  created() {
    // 选中套餐
    // this.get_single();
    // 选中单品
    this.get_item();

    // 获取详情
    if(this.$route.query.id) {
      this.get_deta(this.$route.query.id)
    }
  },
  mounted() {

  },
  methods: {
    // 附加项
    changePlusItemList(e) {

      if(e.length > this.plusLength) {

        let obj= {};

        obj["itemId"]= e[e.length-1];
        obj["selectedItem"]= this.form.productItemList.toString();

        this.$get("product-item/check",obj)
          .then(res => {
            if(res) {
              console.log(res);
            }
          })

      }

      this.plusLength= e.length;

    },
    // 包含项目
    changeProductItemList() {

      let obj= {}
      obj["selectedItem"]= this.form.productItemList.toString();
      obj["fitGender"]= this.form.fitGender;
      obj["productCategory"]= this.form.productCategory;

      if(this.form.productItemList.length !== 0) {
        this.getRest(obj);
      }else {
        this.restList= [];
      }


    },
    // 附加项下拉选
    getRest(y) {
      this.$get("product-item/select/rest",y)
        .then(res => {
          if(res) {
            console.log(res)
            this.restList= res.data;
          }
        })
    },
    // 上下架
    grounding(formName,e) {

      let obj= {};
      obj["productId"]= formName.productId;
      obj["saleEnum"]= e;

      this.$put("product-manager",obj)
        .then(res => {
          if(res) {
            this.Close();
          }
        })

    },
    // 获取详情
    get_deta(y) {
      this.$get("product-manager",y,true)
        .then(res => {
          if(res) {
            console.log(res);

            let obj= {};
            obj["selectedItem"]= res.data.productItemList.toString();
            obj["fitGender"]= res.data.fitGender;
            obj["productCategory"]= res.data.productCategory;

            if(res.data.productItemList.length !== 0) {
              this.getRest(obj);
            }


            this.uploadDisabledProductBanner= "disabled";
            this.uploadProductIntro= "disabled";
            this.uploadDisabledThumbnail= "disabled";
            this.form= res.data;

            this.fileListBanner.push({ url: res.data.productBanner });
            this.fileListIntro.push({ url: res.data.productIntro });
            this.fileListThumbnail.push({ url: res.data.thumbnail });

          }
        })
    },
    // 提交
    onSubmit(formName) {
      console.log(formName)
      this.$refs["form"].validate((valid) => {
        if(valid) {
          console.log(formName);

          // let obj= {};
          // if(formName.productBanner.indexOf("http")){
          //   obj["productBanner"]= formName.productBanner;
          // }
          //
          // obj["productName"]= formName.productName;
          // obj["subhead"]= formName.subhead;
          // obj["price"]= formName.price;
          // obj["productCategory"]= formName.productCategory;
          // obj["fitGender"]= formName.fitGender;
          // obj["fitAge"]= formName.fitAge;
          // obj["productFeature"]= formName.productFeature;
          // obj["productType"]= formName.productType;
          // obj["productItemList"]= formName.productItemList;
          // obj["subProductList"]= formName.subProductList;
          // obj["productDesc"]= formName.productDesc;
          // if(formName.productIntro.indexOf("http")) {
          //   obj["productIntro"]= formName.productIntro;
          // }
          // if(formName.thumbnail.indexOf("http")) {
          //   obj["thumbnail"]= formName.thumbnail;
          // }
          // if(formName.productId) {
          //   obj["productId"]= formName.productId;
          // }
          //
          // console.log(obj)

          this.$post("product-manager",formName)
            .then(res => {
              if(res) {
                console.log(res);

                this.$router.push({
                  path: "/Product"
                })

                this.$message({
                  type: "success",
                  message: "操作成功"
                })

              }
            })

        }
      })
    },
    Close() {
      this.$router.push({
        path: '/Product'
      })
    },
    // 选中单品
    get_item() {
      this.$get("product-item/select")
        .then(res => {
          if(res) {

            this.item= res.data;
          }
        })
    },
    // 选中套餐
    get_single() {
      this.$get("product-manager/single")
        .then(res => {
          if(res) {
            this.single= res.data;
          }
        })
    },
    // 图片查看器
    // 打开
    handlePictureCardPreview() {
      // this.showViewer= true;
    },
    // 关闭
    closeViewer() {
      this.showViewer= false;
      this.showViewerIntro= false;
      this.showViewerThumbnail= false;
    },
    // 删除
    handleRemoveProductBanner(file) {
      console.log(file)
      // let Arr = this.$refs["upload"].uploadFiles
      // let index= Arr.indexOf(file)
      let num= 0;

      this.$refs["uploadProductBanner"].uploadFiles.map(item => {
        if(item.uid == file.uid) {
          this.$refs["uploadProductBanner"].uploadFiles.splice(num,1);
        }
        num ++
      })
      this.uploadDisabledProductBanner= null;
      this.form.productBanner= null;
      this.BannerFalg= false;
      this.fileListBanner= [];

    },
    handleRemoveProductIntro(file) {
      console.log(file)
      // let Arr = this.$refs["upload"].uploadFiles
      // let index= Arr.indexOf(file)
      let num= 0;

      this.$refs["uploadProductIntro"].uploadFiles.map(item => {
        if(item.uid == file.uid) {
          this.$refs["uploadProductIntro"].uploadFiles.splice(num,1);
        }
        num ++
      })
      this.uploadProductIntro= null;
      this.form.productIntro= null;
      this.IntroFalg= false;
      this.fileListIntro= [];
    },
    handleRemoveThumbnail(file) {
      console.log(file)
      // let Arr = this.$refs["upload"].uploadFiles
      // let index= Arr.indexOf(file)
      let num= 0;

      this.$refs["uploadThumbnail"].uploadFiles.map(item => {
        if(item.uid == file.uid) {
          this.$refs["uploadThumbnail"].uploadFiles.splice(num,1);
        }
        num ++
      })
      this.uploadDisabledThumbnail= null;
      this.form.thumbnail= null;
      this.Thumbnail= false;
      this.fileListThumbnail= [];
    },
    // change
    handleChangePicProductBanner(file,fileList){
      console.log(fileList)
      if(fileList.length > 0) {
        this.uploadDisabledProductBanner= "disabled";
      }
    },
    handleChangePicProductIntro(file,fileList) {
      console.log(fileList)
      if(fileList.length > 0) {
        this.uploadProductIntro= "disabled";
      }
    },
    handleChangePicThumbnail(file,fileList) {
      console.log(fileList)
      if(fileList.length > 0) {
        this.uploadDisabledThumbnail= "disabled";
      }
    },
    // 上传成功
    handleAvatarSuccessPicProductBanner(res, file) {
      console.log(res)
      console.log(file)
      this.form.productBanner= res.data.fileName;
    },
    handleAvatarSuccessProductIntro(res) {
      this.form.productIntro= res.data.fileName;
    },
    handleAvatarSuccessThumbnail(res) {
      this.form.thumbnail= res.data.fileName;
    },
    // 上传前
    beforeAvatarUploadPicProductBanner(file) {
      console.log(file)
    },
    beforeAvatarUploadProductIntro() {

    },
    beforeAvatarUploadThumbnail() {

    }
  }
}
</script>

<style lang="less" scoped>
  @import "../../style/Product/ProductDeta.less";
  .disabled{
    /deep/ .el-upload--picture-card{
      display: none!important;
    }
  }
</style>
